@import "reset";
@import "animate.css";

:root {
  --width-content: 85vw;

  --color-body: #191c1e;
  --color-primary: #0089ff;
  --color-buttons: #0089ff;
  --color-borders: #2a2e32; // #2b2d2f
  --color-card: #262A2E; //#fafafd
  --color-card-faded: #262a2d4a; //#fafafd
  --color-detail: #333537; //#262A2E
  --color-text: #9da3a8;
  --color-highlight: #FFFFFF;
  --color-meta: #ccd1d3;
  --color-header: #4D5458;
  --color-input: #0f1011;
  --color-input-border: #4D5458;
  --color-contrast: #ffffff;
  --color-dark: #767c80;
  --color-positive: #82d95a;
  --color-danger: #dc6235;
  --color-warning: #dca235;
  --color-attention: #dca235;
  --color-heat: #ff7622;
  --color-pro: #FF625E;
  --color-verified: #767c80;
  --color-vip: #fcbe72;
  --color-nav-button: transparent;

  --color-splash-left: #FF7F67;
  --color-splash-right: #4496F8;

  @media (prefers-color-scheme: dark) {

  }
}

::-webkit-scrollbar {
  display: none;
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

::-webkit-scrollbar-button {
  display: none;
}

html {
  background: #000000;
  color: var(--color-text);
}

body {
  font: 14px 'Poppins', sans-serif;

  app-root {
    //display: grid;
    //grid-template-rows: 60px 1fr;

    //@media only screen and (max-device-width: 1280px) {
    //  grid-template-rows: 120px 1fr;
    //  grid-template-columns: 1fr;
    //}
  }
}

.body {
  padding-bottom: 20px;
}

.nomobile {
  @media only screen and (max-device-width: 1280px) {
    display: none !important;
  }
}

.mobileonly {
  display: none !important;

  @media only screen and (max-device-width: 1280px) {
    display: block !important;
  }
}

.mobileonly-flex {
  display: none !important;

  @media only screen and (max-device-width: 1280px) {
    display: flex !important;
  }
}

.mobileonly-inline {
  display: none !important;

  @media only screen and (max-device-width: 1280px) {
    display: inline !important;
  }
}

.sticky {
  position: sticky;
  top: 20px;
}

.centered {
  justify-content: center;
  text-align: center;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  //max-width: 1800px;
  padding: 0 20px;
  width: 100%;

  @media only screen and (max-device-width: 1280px) {
    padding: 0 20px;
  }

  &.narrow {
    max-width: 960px;
  }
}

a {
  text-decoration: none;
}

h3 {
  text-transform: uppercase;
}

.badge {
  &.pro {
    color: var(--color-pro);
  }
}

.embed {
  aspect-ratio: 16/9;
  background: #000000;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 20px;

  @media only screen and (max-device-width: 1280px) {
    background: none;
  }

  iframe {
    height: 100%;
    width: 100%;
  }
}

.filters {
  line-height: 50px;
  margin-top: 50px;
  //padding: 10px 0;

  .controls {
    display: grid;
    float: left;
    font-size: 20px;
    grid-template-columns: repeat(2, 40px) 1fr;
    width: 50%;
  }

  .results {
    color: #27385a;
    float: right;
  }
}

.notification-bubble {
  align-items: center;
  aspect-ratio: 1/1;
  background: var(--color-danger);
  border-radius: 100%;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  left: 42px;
  position: absolute;
  top: 0;
}

#primary-wrapper {
  .outer-wrapper {
    background: var(--color-body);
    height: calc(100vh - 102px);
    overflow-x: hidden;
    overflow-y: scroll;
    //padding-bottom: 40px;

    @media only screen and (max-device-width: 1280px) {
      height: auto;
      overflow: visible;
    }

    &::-webkit-scrollbar {
      display: block;
    }
  }

  .header-withactions {
    display: flex;
    justify-content: space-between;

    .buttons {
      display: flex;
      grid-gap: 20px;
      height: 60px;
      padding: 20px 0;

      .button {
        align-items: center;
        display: flex;
        height: 60px;
        justify-content: center;
        min-width: 60px;

        &.icon {
          font-size: 30px;
        }
      }
    }
  }

  h1, .h1 {
    align-items: center;
    color: var(--color-highlight);
    display: flex;
    font-weight: 400;
    text-transform: uppercase;
    text-align: left;

    @media only screen and (max-device-width: 1280px) {
      white-space: break-spaces;
    }

    &::first-letter {
      //margin-left: -0.5%;
    }

    b {
      color: var(--color-highlight);
    }

    a {
      color: var(--color-highlight);
      text-decoration: none;

      &:hover {
        color: var(--color-text);
      }
    }

    .fas {
      padding-right: 3px;
    }

    //.fa-solid {
    //  padding-right: 20px;
    //
    //  &.fa-at {
    //    padding: 0;
    //  }
    //}

    .verified {
      color: #fcbe72;
    }

    .clickable {
      border-bottom: 1px dashed var(--color-meta);
    }

    .meta {
      float: right;
    }
  }

  h2 {
    //font-size: 18px;
    //font-weight: 200;
    //line-height: 20px;
    //text-transform: uppercase;
    //padding-bottom: 15px;
    //opacity: 0.75;
  }

  p {
    line-height: 24px;
  }
}

.pseudo-tab {
  color: var(--color-highlight);
  display: block;
  line-height: 38px;
  padding-bottom: 10px;
}

#footer {
  border-top: 2px solid var(--color-borders);
  //margin-top: 20px;
  padding: 20px 0;

  .post-wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }

  .cta-card {
    background: var(--color-card);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    padding: 20px;
    text-align: center;

    &.discord {
      background: var(--color-card);
      background-image:  linear-gradient(135deg, var(--color-borders) 25%, transparent 25%), linear-gradient(225deg, var(--color-borders) 25%, transparent 25%), linear-gradient(45deg, var(--color-borders) 25%, transparent 25%), linear-gradient(315deg, var(--color-borders) 25%, var(--color-card) 25%);
      background-position:  10px 0, 10px 0, 0 0, 0 0;
      background-size: 20px 20px;
      background-repeat: repeat;

      .button {
        background: #5865F2;
        color: #ffffff;
        margin: auto;
        pointer-events: all;
        width: 25%;

        @media only screen and (max-device-width: 1280px) {
          width: 100%;
        }
      }
    }

    .title {
      color: var(--color-highlight);
      font-size: 24px;
    }

    .button {
      .icon {
        margin-right: 5px;
      }
    }
  }

  .links {
    display: flex;
    justify-content: space-between;

    ul {
      display: flex;
      grid-gap: 20px;

      @media only screen and (max-device-width: 1280px) {
        flex-direction: column;
      }

      li {
        &.disabled {
          cursor: none;
          opacity: 0.5;
        }

        a, .clickable {
          color: var(--color-highlight);
          cursor: pointer;
        }

        .fa-solid {
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
  }

  .disclaimer {
    background: black;
    border-radius: 4px;
    color: var(--color-input-border);
    padding: 20px;
    text-align: center;
  }

  .legal {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-device-width: 1280px) {
      flex-direction: column;
    }

    .credit {
      @media only screen and (max-device-width: 1280px) {
        text-align: center;
      }

      span {
        display: block;

        a {
          color: #ffffff;

          &:hover {
            opacity: 0.5;
          }
        }

        .fa-heart {
          color: #e64b1e;
        }
      }
    }
  }
}

.margin-bottom-10 {margin-bottom: 10px;}
.margin-bottom-20 {margin-bottom: 20px;}
.margin-top-10 {margin-top: 10px;}
.margin-top-20 {margin-top: 20px;}
.nomargin {
  margin: 0 !important;
}

.padding-bottom-10 {padding-bottom: 10px;}
.padding-bottom-20 {padding-bottom: 20px;}
.padding-top-10 {padding-top: 10px;}
.padding-top-20 {padding-top: 20px;}
.nopadding {
  padding: 0 !important;
}

.section-header {
  background: var(--color-borders);
  border-radius: 5px;
  height: 80px;
  padding: 20px;
  position: relative;
  z-index: 5;

  .actions {
    ul {
      height: 80px;
      line-height: 80px;
      position: absolute;
      right: 0;
      top: 0;

      li {
        //background: #12192f;
        //border:2px solid var(--color-borders);
        border-radius: 5px;
        cursor: pointer;
        display: inline-block;
        height: 80px;
        line-height: 80px;
        margin-left: 20px;
        text-align: center;
        vertical-align: text-bottom;
        width: 80px;

        &.active, &:hover {
          background: var(--color-borders);
          color: var(--color-highlight);

          a {
            //color: var(--color-highlight);
          }
        }

        &.button {
          font-size: 18px;
          text-transform: uppercase;
          width: 180px;
        }

        a {
          color: var(--color-text);
          display: block;

          &:hover {
            color: var(--color-highlight);
          }
        }

        .fa-solid, .fa-brands, .fa-regular {
          display: inline-block;
          font-size: 24px;
          //padding-right: 15px;
          text-align: center;
          //width: 30px;
          vertical-align: middle;
        }
      }
    }
  }
}

.modal-wrapper {
  background: rgba(0, 0, 0, 0.65);
  bottom: 0;
  display: flex;
  left: 0;
  opacity: 0;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.6s linear;
  z-index: 9999;

  &.active {
    opacity: 1;
    pointer-events: all;

    .modal {
      transform: translateY(0);

      @media only screen and (max-device-width: 1280px) {
        top: 0;
      }
    }
  }

  &.photo-wrapper {
    justify-content: center;
    padding: 10px;

    img {
      cursor: zoom-out;
      height: 100%;
      object-fit: contain;
    }
  }

  .modal {
    background: var(--color-body);
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0,0,0,0.25);
    //left: 140px;
    margin: auto;
    padding: 20px;
    position: relative;
    //right: 0;
    //top: 160px;
    transform: translateY(-300px);
    transition: transform 0.7s cubic-bezier(0.25, 1, 0.5, 1);
    width: 800px;

    @media only screen and (max-device-width: 1280px) {
      height: 100%;
      overflow: scroll;
      overscroll-behavior-y: contain;
      top: 100px;
      width: 100% !important;
    }

    &.micro {
      width: 200px;
    }

    &.tiny {
      width: 400px;
    }

    &.small {
      width: 600px;
    }

    &.tool-menu {
      width: 600px;

      ul {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(4, 1fr);

        @media only screen and (max-device-width: 1280px) {
          grid-template-columns: repeat(3, 1fr);
        }

        li, a {
          align-items: center;
          aspect-ratio: 1;
          background: var(--color-card);
          border-radius: 5px;
          cursor: pointer;
          display: flex;
          grid-gap: 20px;
          flex-direction: column;
          justify-content: center;
          padding-top: 20px;
          position: relative;

          &:hover {
            color: #ffffff;
          }

          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }

          &.anchored {
            grid-column: 4;
          }

          a {
            color: var(--color-text);
            padding: 0;
          }

          .fa-solid, .fa-regular, .fa-brands {
            font-size: 34px;
          }

          span {
            font-size: 12px;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }

    .form {
      label, .label {
        span {
          color: #ffffff;
        }
      }

      textarea {
        display: block;
        width: 100%;
      }

      .step {
        display: none;

        &.active {
          display: block;
        }
      }
    }

    .search {
      background: var(--color-input);
      border: 1px solid var(--color-borders);
      height: 80px;
      margin-bottom: 20px;
      width: 100%;
    }

    .header {
      display: flex;
      line-height: 40px;
      justify-content: space-between;
      margin-bottom: 20px;

      &.with-note {
        margin-bottom: 10px;
      }

      .title {
        color: #ffffff;
        font-size: 24px;
        text-transform: capitalize;
      }

      .action {
        text-transform: uppercase;

        .fa-solid, .icon {
          font-size: 30px;
          vertical-align: middle;
        }

        .icon {
          color: var(--color-text);
          cursor: pointer;

          &.square {
            align-items: center;
            aspect-ratio: 1/1;
            display: flex;
            height: 40px;
            justify-content: center;
          }
        }
      }
    }

    .note {
      margin-bottom: 20px;

      a {
        color: var(--color-highlight);
      }
    }

    .buttons {
      display: flex;
      grid-gap: 20px;
      margin-top: 20px;

      .button {
        width: 100%;

        .fa-solid {
          margin-right: 5px;
        }
      }
    }

    .button {
      &.close {
        background: var(--color-header);
        color: var(--color-text);
      }
    }
  }
}

.shelf, .card {
  //background: var(--color-card);
  border-radius: 5px;
  line-height: 20px;
  //margin-bottom: 20px;
  min-height: 40px;
  //padding: 20px;

  &.card:last-child {
    //margin-bottom: 0;
  }

  &.card.error {
    align-items: center;
    background: var(--color-danger) !important;
    color: #ffffff;
    display: flex;
    grid-gap: 20px;
    margin-bottom: 20px;
    padding: 20px;

    .fa-solid, .fa-regular {
      font-size: 30px;
    }

    p {
      b {
        display: block;
        font-size: 16px;
        padding-bottom: 5px;
      }

      a {
        color: var(--color-primary);
      }
    }
  }

  &.card.warning {
    align-items: center;
    background: #fff2c2 !important;
    color: #70664b;
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    margin-bottom: 20px;
    padding: 20px;

    .fa-solid, .fa-regular {
      font-size: 30px;
      width: 40px;
    }

    p {
      b {
        display: block;
        font-size: 16px;
        padding-bottom: 5px;
      }

      a, .clickable {
        color: var(--color-primary);
        cursor: pointer;
      }
    }
  }

  &.card.note {
    align-items: center;
    background: var(--color-card) !important;
    //color: #70664b;
    display: flex;
    grid-gap: 20px;
    margin-bottom: 20px;
    padding: 20px;

    .fa-solid, .fa-regular {
      font-size: 30px;
    }
  }

  &.club-notice {
    background: var(--color-header) !important;
  }

  &.network-notice, &.club-notice {
    align-items: center;
    background: var(--color-card);
    display: flex;
    height: 80px;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px;
    text-transform: uppercase;

    &.network-speeddart {
      --color-card: #000000;
      --color-highlight: #fedc07;
    }

    &.network-dartzone {
      --color-card: #ffffff;
      --color-highlight: #d2202f;
      --color-text: #abadb0;
    }

    &.network-bta {
      --color-card: #ffffff; //#2b353e
      --color-highlight: #0074f8;
      --color-text: #2b353e;
    }

    .col {
      align-items: center;
      color: var(--color-text);
      display: flex;
      height: 100%;
      width: 100%;

      &.centered {
        justify-content: center;
      }

      &.action {
        cursor: pointer;
        grid-gap: 10px;
        justify-content: flex-end;

        &:hover {
          color: var(--color-highlight);
        }

        .fa-solid {
          font-size: 24px;
        }
      }
    }

    img {
      height: 100%;
    }

    .label {
      color: #ffffff;
    }

    .button {
      align-items: center;
      background: transparent;
      border: 2px solid #ffffff;
      display: flex;
      grid-gap: 10px;
      height: 60px;
      padding: 0 20px;
    }
  }

  .shelf-content {
    min-height: 80px;
  }
}

.card {
  display: flex;
  flex-direction: column;

  &.view-grid {
    height: 100%;
  }

  .card-header {
    display: flex;
    padding: 10px;
    width: 100%;

    .user {
      display: flex;
      grid-gap: 10px;

      @media only screen and (max-device-width: 1280px) {
        grid-gap: 5px;
      }

      .user-photo {
        background: var(--color-header);
        border-radius: 2px;
        width: 40px;

        @media only screen and (max-device-width: 1280px) {
          height: 20px;
          width: 20px;
        }

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }

        .initials {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
        }
      }

      .details {
        display: flex;
        flex-direction: column;

        .meta {
          //font-size: 12px;
          @media only screen and (max-device-width: 1280px) {
            display: none;
          }
        }
      }
    }

    .actions {
      display: flex;
      grid-gap: 10px;
      margin-left: auto;

      li {
        aspect-ratio: 1/1;
        color: var(--color-header);
        cursor: pointer;
        font-size: 28px;
        height: auto;
        line-height: 40px;
        text-align: center;
        width: 40px;
      }
    }
  }

  .card-banner {
    align-items: center;
    aspect-ratio: 16/9;
    background: var(--color-header);
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 2px solid var(--color-header);
    border-top: 2px solid var(--color-header);
    //border-radius: 3px;
    display: flex;
    flex-shrink: 0;
    font-size: 30px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;

    img, app-photo {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    .icon {
      bottom: 0;
      color: #ffffff;
      font-size: 14px;
      padding: 10px;
      position: absolute;
      right: 0;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    height: 100%;
    padding: 10px;
    width: 100%;

    &.embed-preview {
      border: 2px solid var(--color-header);
      border-radius: 3px;
      margin: 10px 10px 0 10px;
      padding: 0 0 10px 0;
      width: auto;

      .card-banner {
        border-bottom: 2px solid var(--color-header);
      }

      .title, p, .link {
        padding: 0 10px;
      }

    }

    .title {
      color: var(--color-highlight);
      display: block;
      line-height: 24px;
      max-height: 48px;
      overflow: hidden;
    }

    .link {
      color: var(--color-header);
      display: block;
      margin-top: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  .card-footer {
    display: flex;
    grid-gap: 15px;
    margin-top: auto;
    padding: 10px;
    width: 100%;

    li {
      align-items: center;
      display: flex;
      grid-gap: 5px;

      @media only screen and (max-device-width: 1280px) {
        font-size: 12px;
      }

      &.highlighted {
        color: var(--color-highlight);
      }

      &.anchored {
        margin-left: auto;
      }

      &.small {
        font-size: 12px;
      }
    }
  }
}

.shelves {
  .shelf {
    //background: var(--color-borders);
    //border-radius: 5px;
    //line-height: 20px;
    //margin-bottom: 20px;
    //min-height: 80px;
    //padding: 20px;
    //
    //.shelf-content {
    //  min-height: 80px;
    //}

    &.card {
      background: var(--color-card);
      border-radius: 5px;
      padding: 20px;
    }
  }
}

.user-photo {
  //border: 2px solid var(--color-borders);
  aspect-ratio: 1;
  border-radius: 5px;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
}

.button {
  background: var(--color-buttons);
  //border: 2px solid var(--color-borders);
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  display: block;
  height: 80px;
  line-height: 80px;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  &:focus {
    pointer-events: none;

    .context-menu {
      display: block !important;
      pointer-events: all;
    }
  }

  &.grey {
    background: var(--color-header);
    color: var(--color-text);
  }

  &.green {
    background: var(--color-positive);
  }

  &.busy {
    opacity: 0.8;
    pointer-events: none;

    .text {
      opacity: 0.2;
    }

    .spinner {
      opacity: 1;
    }
  }

  &.wide {
    display: block;
    grid-column: 1 / span 4;

    @media only screen and (max-device-width: 1680px) {
      grid-column: 1 / span 4;
    }
  }

  &.mini {
    height: 40px;

    .context-menu {
      top: 60px;
    }
  }

  &.disabled {
    background: var(--color-header);
    color: var(--color-text);
    opacity: 0.5;
    pointer-events: none;
  }

  &.outline {
    background: transparent;
    border: 1px solid;
    color: var(--color-header) !important;
  }

  &.ghost {
    background: none !important;
  }

  .text {
    transition: opacity 0.2s linear;
  }

  .spinner {
    align-items: center;
    bottom: 0;
    display: flex;
    font-size: 30px;
    justify-content: center;
    left: 0;
    right: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.2s linear;
  }
}

.context-menu {
  background: var(--color-card);
  border-radius: 4px;
  box-shadow: 0 3px 8px rgba(0,0,0,0.2);
  display: none !important;
  font-size: 14px;
  line-height: 24px;
  min-width: 220px;
  padding: 10px;
  position: absolute;
  right: 0;
  text-transform: capitalize;
  top: 80px;
  z-index: 9999;

  li {
    align-items: center;
    color: var(--color-text);
    cursor: pointer;
    display: flex;
    grid-gap: 10px;
    padding: 10px;

    &:hover {
      color: var(--color-highlight);
    }

    &.disabled {
      color: var(--color-header);
      cursor: not-allowed;
      pointer-events: none;
    }

    .icon {
      font-size: 20px;
      width: 24px;
    }
  }
}

.panels {
  .shelves {
    display: none;

    &.active {
      display: block;
    }
  }
}

markdown {
  &.inline {
    p {
      margin: 0;
    }

    a {
      color: var(--color-highlight) !important;
      display: inline !important;
      padding: 0 !important;
    }
  }

  h3, h4 {
    color: #ffffff;
  }

  h1, h2, h3 {
    //color: var(--color-borders) !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    height: auto !important;
    line-height: 40px !important;
    margin-bottom: 0px !important;
    margin-top: 20px !important;
    text-transform: uppercase !important;
    word-break: break-all;

    &:first-child {
      margin-top: 0px !important;
    }
  }

  h4 {
    padding-top: 10px;
  }

  a {
    color: var(--color-primary);
    text-decoration: none;

    &:hover {
      opacity: 0.75;
    }
  }

  ul {
    line-height: 26px;
    list-style: disc;
    margin-bottom: 20px;
    padding-left: 1em;
    white-space: normal;

    li {
      margin: 10px 0;
      padding-left: 5px;
    }

    ul {
      list-style: circle;
      margin-bottom: 0;
    }
  }

  p {
    line-height: 26px;
    margin: 10px 0;
    white-space: break-spaces;
  }
}

header {
  line-height: 60px;

  h1, .h1 {
    font-size: 12px;
    line-height: 60px;
    padding: 20px 0;

    @media only screen and (max-device-width: 1280px) {
      display: block;
      padding-top: 20px;
    }

    &.active {

    }

    .meta {
      color: var(--color-text);
    }
  }

  .meta.action {
    cursor: pointer;
    float: right;
    text-transform: uppercase;

    &:hover {
      color: #ffffff;
    }

    .fas, .far, .fa-solid {
      margin: 0;
      padding: 0;
    }
  }
}

.masthead {
  align-items: center;
  background: var(--color-card);
  border-radius: 3px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  line-height: 20px;
  margin-bottom: 20px;
  min-height: 120px;
  padding: 20px;

  h1 {
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .actions {
    display: flex;
    grid-gap: 15px;
    height: 60px;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 10;

    .button {
      align-items: center;
      aspect-ratio: 1/1;
      background: var(--color-header);
      color: var(--color-text);
      display: flex;
      font-size: 24px;
      height: 100%;
      justify-content: center;

      &.wide {
        aspect-ratio: unset;
      }
    }
  }

  .card {
    margin-bottom: 0;
  }
}

.post-header {
  display: flex;
  grid-gap: 20px;
  padding: 20px 0;

  @media only screen and (max-device-width: 1280px) {
    display: grid;
    grid-template-columns: 60px 1fr;
  }

  .user-photo {
    background: var(--color-header);
    flex-shrink: 0;
    height: 60px;
    text-align: center;
    width: 60px;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;

    @media only screen and (max-device-width: 1280px) {
      grid-column: 1 / span 2;
      grid-row: 2;
    }

    h1 {
      font-size: 30px;
      line-height: 30px;
      padding: 0;
    }

    .meta {
      line-height: 20px;

      h2 {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;

        b {
          color: var(--color-highlight);
        }
      }
    }
  }

  .buttons {
    display: flex;
    grid-gap: 20px;
    margin-left: auto;

    .temperature {
      color: var(--color-header);
      font-size: 24px;
    }

    .button {
      align-items: center;
      display: flex;
      font-size: 12px;
      height: 60px;
      justify-content: center;
      padding: 0 20px;
      text-transform: uppercase;
      white-space: nowrap;

      &.heat {
        position: relative;

        &.active {
          background: var(--color-heat);
          pointer-events: none;
        }

        .temperature {
          background: #000000;
          border-radius: 3px;
          bottom: 0;
          color: #ffffff;
          font-size: 12px;
          line-height: 20px;
          margin: 0 auto;
          min-width: 40px;
          position: absolute;
          transform: translateY(10px);
        }
      }

      &.icon {
        aspect-ratio: 1/1;
        background: var(--color-header);
        font-size: 24px;
        padding: 0;

        &.blue {
          background: var(--color-primary);
        }

        &.discord {
          background: #5865F2;
        }
      }

      a {
        color: #ffffff;
      }
    }
  }
}

.post-wrapper {
  flex-shrink: 0;
  margin: 0 auto;
  max-width: 800px;
  width: 70%;

  @media only screen and (max-device-width: 1280px) {
    padding: 0 20px;
    width: 100%;
  }

  &.wide {
    max-width: 1310px;
    width: 100%;
  }
}

.post-body {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  //margin: 0 20px;
  padding-bottom: 20px;

  &.card {
    background: var(--color-card);
    border-radius: 5px;
    line-height: 20px;
    margin-bottom: 20px;
    min-height: 40px;
    padding: 20px;
  }
}

.tabs {
  //background: var(--color-borders);
  //border-bottom: 1px solid var(--color-meta);
  //border-radius: 5px;
  //color: var(--color-highlight);
  display: flex;
  font-size: 12px;
  grid-gap: 30px;
  height: 100px; // OLD: 60px
  line-height: 60px;
  padding: 20px 0;
  //padding: 20px 0;
  text-align: center;
  text-transform: uppercase;

  @media only screen and (max-device-width: 1280px) {
    grid-gap: 0;
    justify-content: space-between;
  }

  &.mobileonly {
    .actions {
      .buttons {
        .button {
          width: 100%;
        }
      }
    }
  }

  li {
    color: var(--color-text);
    cursor: pointer;
    height: 60px;
    //margin: 10px 0;
    //margin-right: 30px;

    @media only screen and (max-device-width: 1280px) {
      width: 100%;
    }

    &:hover {
      color: var(--color-highlight);
    }

    &.active {
      border-bottom: 2px solid var(--color-primary);
      color: var(--color-highlight);
    }

    &.action, &.note {
      margin-left: auto;
    }

    &.actions {
      margin-left: auto;

      .toggle {
        border: 2px solid var(--color-card);
        border-radius: 4px;
        display: flex;

        .button {
          align-items: center;
          aspect-ratio: 1/1;
          color: var(--color-header);
          display: flex;
          font-size: 30px;
          height: 60px;
          justify-content: center;
          width: 60px;

          &:hover {
            color: var(--color-text);
          }

          &.active {
            background: var(--color-card) !important;
            border: none !important;
          }
        }
      }

      .buttons {
        display: flex;
        grid-gap: 20px;
        margin-bottom: 20px;

        .button {
          line-height: 60px;
          width: 305px;

          &.icon {
            aspect-ratio: 1/1;
            background: var(--color-header);
            color: var(--color-text);
            font-size: 30px;
            height: 60px;
            width: 60px;

            &.wide {
              width: 100%;
            }

            &.discord {
              //background: #5865F2;
            }
          }

          &.blue {
            color: #ffffff;
          }

          a {
            color: #ffffff;
            display: block;

            .fa-solid {
              padding-left: 5px;
            }
          }
        }
      }
    }

    &.note {
      cursor: default;

      &:hover {
        color: var(--color-text);
      }
    }

    &.disabled {
      cursor: not-allowed;
      position: relative;

      &::after {
        align-items: center;
        bottom: 0;
        content: '\f023';
        display: flex;
        font-family: 'Font Awesome 6 Free';
        font-size: 12px;
        font-weight: 900;
        justify-content: center;
        left: 0;
        line-height: initial;
        //opacity: 0.25;
        position: absolute;
        right: 0;
        top: 0;
      }

      span {
        opacity: 0.25;
        pointer-events: none;
      }
    }

    h3 {
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.card-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  position: relative;

  @media only screen and (max-device-width: 1280px) {
    display: flex;
    flex-direction: column;
  }

  &.masonry {
    @media only screen and (max-device-width: 1280px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.grid-2-up {
    grid-template-columns: repeat(2, 1fr);
  }

  .card {
    background: var(--color-card);
    overflow: hidden;

    &.format-bta {
      .banner {
        box-shadow: 0 0 20px #1461BF;
      }

      .body {
        border-top: 4px solid #1461BF;
      }
    }

    &.format-speeddart {
      .banner {
        box-shadow: 0 0 20px #ffdb01;
      }

      .body {
        border-top: 4px solid #ffdb01;
      }
    }

    &.format-quiknrf {
      .banner {
        box-shadow: 0 0 20px #ef232f;
      }

      .body {
        border-top: 4px solid #ef232f;
      }
    }

    &.placeholder {
      background: var(--color-card-faded);
      cursor: default;
    }

    .banner {
      aspect-ratio: 16/9;
    }

    .body {
      padding: 20px;
      position: relative;

      .title {
        display: block;
        font-size: 18px;
      }

      .small {
        font-size: 12px;
        text-transform: uppercase;

        .fa-solid {
          margin-right: 2px;
        }
      }

      a {
        color: var(--color-contrast);
        text-decoration: none;

        &:hover {
          color: var(--color-primary);
        }
      }

      .metrics {
        display: flex;
        font-size: 12px;
        grid-gap: 20px;
        margin-top: 20px;
        justify-content: space-between;
        text-transform: uppercase;
      }
    }
  }
}

.card-list {
  position: relative;

  .card {
    align-items: center;
    background: var(--color-card);
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 20px;
  }
}

form, .form {
  .field-label {
    color: var(--color-highlight);
    display: block;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .field-row {
    display: flex;
    grid-gap: 20px;

    @media only screen and (max-device-width: 1280px) {
      flex-direction: column;
    }

    &.field-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @media only screen and (max-device-width: 1280px) {
        grid-template-columns: 1fr;
      }
    }

    .field-column {
      width: 100%;
    }
  }

  .field-list {
    .button {
      aspect-ratio: 1/1;
      align-items: center;
      background: var(--color-header);
      display: flex;
      height: 80px;
      justify-content: center;
      width: 80px;
    }

    .field-list-add {
      display: flex;
      grid-gap: 10px;

      select {
        text-transform: uppercase;
        width: 100%;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
    }

    li {
      align-items: center;
      display: flex;
      grid-gap: 10px;

      label {
        margin: 0;
        width: 100%;

        input {
          text-transform: uppercase;
        }
      }
    }
  }

  .field-table {
    flex-direction: column;

    .table-header {
      color: #ffffff;
      display: grid;
      grid-auto-flow: column;
      grid-gap: 20px;
      width: 100%;
    }

    .table-row {
      align-items: center;
      display: grid;
      grid-auto-flow: column;
      grid-gap: 20px;
      width: 100%;

      label {
        margin: 0;
      }
    }
  }

  hr {
    border: none;
    border-top: 1px solid var(--color-borders);
    margin: 40px 0;
  }

  .button {
    line-height: 80px;
  }

  .or {
    line-height: 80px;
    padding-top: 40px;

    @media only screen and (max-device-width: 1280px) {
      line-height: normal;
      padding: 0;
    }
  }

  label, .label {
    display: block;
    margin-bottom: 20px;
    width: 100%;

    &:focus-within {
      markdown-toolbar {
        background: #ffffff;
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;

      span {
        b {
          display: none;
        }
      }
    }

    &.dirty.invalid {
      --color-header: var(--color-danger);

      span {
        color: var(--color-danger);
      }

      input {
        border-color: var(--color-danger);
      }
    }

    &.half {
      flex-basis: 50%;
    }

    &.setting-toggle {
      align-items: center;
      background: var(--color-borders);
      border-radius: 4px;
      color: var(--color-text);
      display: flex;
      grid-gap: 10px;
      height: 80px;
      justify-content: space-between;
      padding: 0 20px;

      .icon {
        align-items: center;
        aspect-ratio: 1/1;
        display: flex;
        flex-shrink: 0;
        font-size: 30px;
        height: 40px;
        justify-content: center;
      }

      .details {
        display: flex;
        flex-direction: column;

        dt {
          color: var(--color-highlight);

          .tag {
            align-items: center;
            background: var(--color-header);
            border-radius: 10px;
            display: inline-flex;
            line-height: 20px;
            padding: 0 4px;

            .fa-solid, .fa-regular {
              font-size: 14px;
              margin-right: 4px;
            }
          }
        }
      }

      .button {
        height: 40px;
        line-height: 40px;
        margin-left: auto;
        min-width: 40px;
      }

      .switch {
        aspect-ratio: 2/1;
        background: var(--color-body);
        border-radius: 15px;
        height: 30px;
        margin-left: auto;
        //padding: 3px;
        position: relative;
        transition: background 0.3s linear;

        &.active {
          background: var(--color-primary);

          b {
            background: #ffffff;
            left: 50%;
          }
        }

        b {
          aspect-ratio: 1/1;
          background: var(--color-header);
          border-radius: 100%;
          display: block;
          height: 26px;
          left: 0;
          margin: 2px;
          position: absolute;
          transition: left 0.2s ease-in-out, background 0.3s linear;
        }
      }

      input {
        display: none;
      }
    }

    &.checkbox, &.radio {
      align-items: center;
      display: flex;
      grid-gap: 10px;
    }

    &.upload {
      .input {
        align-items: center;
        display: flex;
        height: 80px;
        padding: 0;

        .preview {
          aspect-ratio: 1/1;
          align-items: center;
          border-right: 2px solid var(--color-header);
          display: flex;
          height: 100%;

          span {
            color: var(--color-header);
            font-size: 20px;
            text-align: center;
            width: 100%;
          }

          img {
            padding: 10px;
          }
        }

        .action {
          color: var(--color-text);
          text-align: center;
          text-transform: uppercase;
          width: 100%;
        }

        input {
          display: none;
        }
      }
    }

    &.color {
      padding: 0;
    }

    .input-list {
      display: flex;
      grid-gap: 15px;
    }

    .checkbox-list, .button-list, .radio-list {
      max-height: 300px;
      overflow: scroll;

      .card {
        grid-gap: 20px;
        justify-content: flex-start;
        margin-bottom: 10px;
      }
    }

    .button-list {
      label {
        display: flex;
        line-height: 40px;
        justify-content: space-between;

        .button {
          height: 40px;
          width: 25%;
        }
      }
    }

    span {
      display: block;
      line-height: 40px;

      em {
        color: var(--color-header);
        float: right;
        font-style: normal;

        &.error {
          color: var(--color-danger);
        }
      }
    }

    input, select, textarea, .input {
      background: var(--color-body);
      border: 2px solid var(--color-input-border);
      border-radius: 4px;
      color: var(--color-text);
      height: 80px;
      line-height: 80px;
      outline: none;
      padding: 0 20px;
      width: 100%;

      &:focus {
        //border-color: var(--color-primary);
        border-color: #ffffff;
      }
    }

    input[type="color"] {
      margin: 0;
      padding: 0;
      -webkit-appearance: none;
      //border: none;

      &::-webkit-color-swatch-wrapper {
        padding: 0;
      }

      &::-webkit-color-swatch {
        border: none;
      }
    }

    input[type="number"] {
      margin: 0;
      -moz-appearance: textfield !important;
      -webkit-appearance: none;
    }

    input[type="checkbox"], input[type="radio"] {
      height: 30px;
      line-height: 30px;
      margin: 0;
      width: 30px;
    }

    select {

    }

    textarea {
      line-height: 20px;
      min-height: 200px;
      padding: 10px;
      resize: vertical;

      &.markdown-linked {
        border-radius: 0 0 4px 4px;
      }
    }

    .copyable {
      align-items: center;
      background: var(--color-card);
      color: var(--color-input-border);
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      &:focus {
        border-color: var(--color-input-border);
        position: relative;

        &::after {
          background: var(--color-card);
          bottom: 0;
          content: 'Copied!';
          left: 0;
          position: absolute;
          right: 0;
          text-align: center;
          top: 0;
        }
      }

      span {
        color: var(--color-input-border) !important;
      }

      .icon {
        font-size: 18px;
      }
    }
  }

  .toggle {
    background: var(--color-body);
    border: 2px solid var(--color-header);
    border-radius: 4px;
    color: var(--color-text);
    display: flex;
    text-align: center;
    width: 100%;

    li {
      border-left: 1px solid var(--color-header);
      border-right: 1px solid var(--color-header);
      cursor: pointer;
      line-height: 40px;
      width: 100%;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      &.active {
        background: var(--color-primary);
        color: #ffffff;
      }
    }
  }

  .submit {
    &.disabled {
      opacity: 0.5;
    }
  }
}

markdown-toolbar {
  background: var(--color-header);
  border-radius: 4px 4px 0 0;
  color: var(--color-body);
  display: flex;

  & > * {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .anchored {
    margin-left: auto;
  }

  .fa-solid {
    padding: 10px;
  }
}

.players-list {
  display: grid;
  grid-gap: 20px;

  li {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 4fr  1fr 1fr 40px;
    line-height: 40px;
    margin-bottom: 0;
    text-align: center;

    @media only screen and (max-device-width: 1280px) {
      display: flex;
      grid-gap: 0;
      justify-content: space-between;
    }

    .name {
      //color: #ffffff;
      text-align: left;
    }

    a {
      color: #ffffff;

      &:hover {
        color: var(--color-text);
      }
    }

    .user-photo {
      align-items: center;

      &.large {
        img {
          height: 100%;
          object-fit: cover;
          margin: 0;
          padding: 0 !important;
        }
      }

      img {
        border-radius: 2px;
        display: inline-block;
        height: 18px;
        margin-right: 5px;
        vertical-align: middle;
      }

      span {
        background: var(--color-header);
        border-radius: 2px;
        color: var(--color-text);
        display: inline-block;
        font-size: 10px;
        height: 18px;
        line-height: 18px;
        margin-right: 5px;
        text-align: center;
        width: 18px;
        vertical-align: middle;
      }
    }

    .verified {
      color: #fcbe72;
      font-size: 10px;
      vertical-align: super;
    }

    .status {
      &.status-hosting {
        color: var(--color-positive);
      }

      &.status-checkedin {
        color: var(--color-positive);
      }
    }

    .actions {
      align-items: flex-end;
      display: flex;

      .fa-solid, .fa-regular {
        aspect-ratio: 1/1;
        background: var(--color-header);
        border-radius: 2px;
        color: var(--color-text);
        height: 100%;
        line-height: 40px;
        min-width:40px;
        text-align: center;
        //width: 40px;
      }
    }
  }
}

.match-list {
  .match {
    align-items: center;
    cursor: pointer;
    display: flex;
    //grid-gap: 20px;
    position: relative;

    &:hover {
      opacity: 0.75;
    }

    &.match-pending {
      background: none !important;
      border: 2px solid var(--color-card);
    }

    &.match-certified {
      .icon {
        .fa-solid, .fa-regular {
          color: var(--color-positive);
        }
      }
    }

    &.participants-2 {
      @media only screen and (max-device-width: 1280px) {
        flex-direction: column;
        grid-gap: 20px;

        .participants {
          flex-direction: column;
          grid-gap: 20px;

          &::after {
            display: none;
          }

          .participant {
            &:nth-child(2) {
              flex-direction: row;
            }

            .team {
              text-align: left;

              .ranking {
                float: none !important;
              }
            }

            .score {
              height: 48px;
            }
          }
        }
      }

      .participant:nth-child(2) {
        flex-direction: row-reverse;

        .team {
          text-align: left;

          .meta {
            .ranking {
              float: left;
            }
          }
        }
      }
    }

    .icon, .actions {
      width: 40px;

      .fa-solid, .fa-regular, .fa-brands {
        align-items: center;
        color: var(--color-header);
        display: flex;
        font-size: 20px;
        height: 40px;
        justify-content: center;
        text-align: center;
        width: 40px;

        &.icon-vod {
          color: var(--color-header);
        }
      }
    }

    .icon {
      .fa-solid, .fa-regular, .fa-brands {
        font-size: 26px;
      }
    }

    .participants {
      align-items: center;
      display: flex;
      grid-gap: 40px;
      justify-content: center;
      position: relative;
      width: 100%;

      &::after {
        content: '\f068';
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        text-align: center;
        width: 40px;
      }

      .participant {
        align-items: center;
        display: flex;
        grid-gap: 10px;
        width: 100%;

        &.winner {
          .score {
            color: var(--color-positive);
          }
        }

        .team {
          color: #ffffff;
          text-align: right;
          width: 100%;

          .meta {
            color: var(--color-meta);
            display: block;

            .ranking {
              float: right;
              margin-top: 2px;
              //opacity: 0.5;
            }
          }
        }

        .user-photo {
          align-items: center;
          border-radius: 2px;
          display: flex;
          flex-shrink: 0;
          height: 40px;
          justify-content: center;
          width: 40px;
        }

        .score {
          align-items: center;
          border: 2px solid var(--color-header);
          border-radius: 2px;
          color: var(--color-meta);
          display: flex;
          font-size: 20px;
          height: 40px;
          justify-content: center;
          min-width: 40px;
          text-align: center;
        }
      }
    }

    .actions {
      align-items: flex-end;
      display: flex;
      margin-left: auto;
      width: 40px;

      .menu-toggle {
        border: 2px solid var(--color-header);
        border-radius: 2px;
      }
    }
  }
}

.teams-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-device-width: 1280px) {
    grid-template-columns: 1fr;
  }

  .shelf {
    background: none !important;
    border: 2px solid var(--color-card);
    margin-bottom: 0;

    .header {
      align-items: center;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      grid-gap: 20px;
      justify-content: space-between;
      margin-bottom: 0 !important;
      padding: 20px;

      .name {
        align-items: center;
        color: var(--color-text);
        display: flex;
        grid-gap: 10px;

        &.clickable {
          color: #ffffff;
          cursor: pointer;

          &:hover {
            opacity: 0.75;
          }
        }

        .logo {
          align-items: center;
          aspect-ratio: 1/1;
          background: var(--color-header);
          border-radius: 2px;
          color: var(--color-text);
          display: flex;
          font-size: 20px;
          justify-content: center;
          position: relative;
          width: 40px;

          img {
            position: relative;
            z-index: 1;
          }

          .stripe {
            background: black;
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 50%;
            z-index: 0;
          }

        }
      }

      .score, .actions {
        align-items: center;
        border: 2px solid var(--color-header);
        border-radius: 2px;
        color: var(--color-text);
        display: flex;
        font-size: 20px;
        height: 40px;
        justify-content: center;
        min-width: 40px;
        padding: 10px;
        text-align: center;
      }

      .score {
        margin-left: auto;
      }

      .actions {
        background: var(--color-header);
        font-size: 14px;
      }
    }

    .players-list {
      padding: 20px;

      li {
        //grid-template-columns: 4fr 1fr;
        display: flex;
        justify-content: space-between;

        &.placeholder {
          justify-content: center;
        }
      }
    }
  }
}

.clubs-grid {
  @media only screen and (max-device-width: 1280px) {
    display: flex;
    flex-direction: column;
  }

  .card {
    cursor: pointer;
    display: flex;
    flex: 1 1 0px;
    flex-direction: column;
    margin-bottom: 0 !important;
    //max-width: 305px;
    //width: 305px;

    @media only screen and (max-device-width: 1280px) {
      flex: auto;
      max-width: none;
      width: auto;
    }

    &:hover {
      .banner {
        opacity: 0.75;
      }
    }

    &.photo-but-club {
      aspect-ratio: 1/1;

      .banner {
        cursor: zoom-in;
        height: 100%;
      }
    }

    &.team-but-club {
      .banner {
        justify-content: center;
        padding: 0 20px;

        img {
          height: 100%;
          object-fit: contain;
          width: auto;
        }
      }
    }

    &.match-but-club {
      .tag {
        z-index: 5;
      }

      .participants {
        align-items: center;
        display: flex;
        position: relative;
        text-align: center;
        width: 100%;

        &::after {
          align-items: center;
          aspect-ratio: 1;
          background: #000000;
          border-radius: 3px;
          content: 'VS';
          display: flex;
          justify-content: center;
          left: 0;
          margin: auto;
          position: absolute;
          right: 0;
          width: 10%;
        }

        //&::before {
        //  align-items: center;
        //  background: #000000;
        //  bottom: 0;
        //  content: '';
        //  left: 0;
        //  margin: auto;
        //  position: absolute;
        //  right: 0;
        //  top: 0;
        //  width: 2px;
        //}

        .participant {
          align-items: center;
          color: #ffffff;
          display: flex;
          font-size: 36px;
          height: 100%;
          justify-content: center;
          position: relative;
          width: 100%;

          &:first-child {
            background: var(--color-splash-left);
          }

          &:last-child {
            background: var(--color-splash-right);
          }

          img {
            object-fit: cover;
            height: 100%;
          }
        }
      }
    }

    &.product-but-club {
      .seller {
        display: flex;
        grid-gap: 5px;
        text-transform: uppercase;

        &:hover {
          color: var(--color-highlight);
        }

        .user-photo {
          border-radius: 2px;
          display: inline-block;
          height: 18px;
          overflow: hidden;
          width: 18px;

          img {
            background: var(--color-header);
            height: 18px;
            object-fit: cover;
          }

          span {
            background: var(--color-header);
            border-radius: 2px;
            color: var(--color-text);
            display: inline-block;
            font-size: 10px;
            height: 18px;
            line-height: 18px;
            //margin-right: 5px;
            text-align: center;
            width: 18px;
            vertical-align: middle;
          }
        }
      }
    }

    &.loadmore {
      align-items: center;
      background: none;
      border: 2px solid var(--color-card);
      color: var(--color-card);
      font-size: 40px;
      justify-content: center;
      text-align: center;

      &:hover {
        color: var(--color-header);
      }

      a {
        color: var(--color-card);

        &:hover {
          color: var(--color-header);
        }
      }
    }

    .banner {
      background: var(--color-detail);
      background-size: cover;
      display: flex;
      //height: 172px;
      overflow: hidden;
      position: relative;
      text-transform: uppercase;

      img, app-photo {
        object-fit: cover;
        width: 100%;
      }

      .initials {
        align-items: center;
        bottom: 0;
        display: flex;
        font-size: 50px;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .tag {
      background: rgba(0, 0, 0, 0.75);
      border-radius: 3px;
      color: var(--color-highlight);
      display: inline-block;
      float: right;
      font-size: 12px;
      margin: 20px;
      padding: 4px 8px;
      position: absolute;
      right: 0;
      text-transform: uppercase;
    }

    .body {
      border-top: 4px solid var(--color-header);
      display: flex;
      flex: 1 1 0px;
      flex-direction: column;
      //height: 50%;
      min-height: 130px;

      .metrics {
        //color: var(--color-header);
        color: var(--color-text);
        margin-top: auto;

        li {
          line-height: 20px;

          .clickable {
            cursor: pointer;

            &:hover {
              color: var(--color-highlight);
            }
          }

          .user-photo {
            aspect-ratio: 1/1;
            background: var(--color-header);
            border-radius: 2px;
            display: inline-block;
            height: 20px;
            margin-right: 5px;
            text-align: center;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.games-grid {
  &.feature-grid {
    aspect-ratio: 16/3;
    grid-template-columns: repeat(3, 1fr);

    @media only screen and (max-device-width: 1280px) {
      aspect-ratio: 16/8;
    }

    .card {
      aspect-ratio: 16/3;
      cursor: pointer;
      position: relative;

      @media only screen and (max-device-width: 1280px) {
        aspect-ratio: 16/8;
      }

      &:first-child {
        grid-column: 1 / span 3;
      }

      &:hover {
        .promo img {
          opacity: 0.5;
        }
      }

      &::before {
        background: linear-gradient(180deg, rgb(37 42 45 / 10%) 0%, rgb(37 42 45 / 30%) 30%, rgb(37 42 45 / 80%) 75%);
        bottom: 0;
        content: '';
        left: 0;
        //mix-blend-mode: exclusion;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }

      .promo {
        bottom: 0;
        display: flex;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;

        img {
          border-radius: 5px;
          filter: contrast(0.75);
          //mix-blend-mode: soft-light;
          object-fit: cover;
          opacity: 0.8;
          overflow: hidden;
          width: 100%;
        }
      }

      .details-wrapper {
        //background: linear-gradient(180deg, rgb(37 42 45 / 20%) 0%, rgb(37 42 45 / 70%) 40%, var(--color-card) 100%);
        background: linear-gradient(180deg, rgb(37 42 45 / 0%) 0%, rgb(37 42 45 / 30%) 30%, rgb(37 42 45 / 50%) 75%);
        bottom: 0;
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        justify-content: flex-end;
        left: 0;
        padding: 10px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        //
        //&::before {
        //  background: var(--color-card);
        //  bottom: 0;
        //  content: '';
        //  left: 0;
        //  mix-blend-mode: lighten;
        //  position: absolute;
        //  right: 0;
        //  top: 0;
        //  z-index: 0;
        //}

        .host {
          align-items: center;
          display: flex;

          .user-photo {
            height: 120px;

            @media only screen and (max-device-width: 1280px) {
              height: 80px;
            }

            &:hover {
              opacity: 0.8;
            }
          }

          .details {
            //background: linear-gradient(180deg, rgb(37 42 45 / 0%) 0%, rgb(37 42 45 / 30%) 30%, rgb(37 42 45 / 50%) 75%);
            display: flex;
            grid-gap: 10px;
            flex-direction: column;

            .title {
              color: var(--color-highlight);
              font-size: 28px;

              @media only screen and (max-device-width: 1280px) {
                font-size: 20px;
                line-height: 24px;
              }
            }

            .byline {
              //padding-bottom: 10px;
            }

            .meta {
              display: flex;
              grid-gap: 10px;
            }
          }
        }

        .details {
        }
      }
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.block {
  display: block;
  width: 100%;
}

.custom-leaflet-marker {
  filter: drop-shadow(0 0 3px var(--color-body));

  .pin {
    aspect-ratio: 1/1;
    background: var(--color-detail);
    border-radius: 3px 3px 0 0;
    color: var(--color-header);
    padding: 2px;
    position: relative;

    &:hover {
      background: var(--color-header);
      color: var(--color-text);

      &::after {
        border-top-color: var(--color-header)
      }
    }

    &::after {
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 10px solid var(--color-detail);
      content: '';
      left: 0;
      height: 0;
      position: absolute;
      top: 100%;
      width: 0;
    }
  }

  .logo {
    aspect-ratio: 1/1;
    //background: var(--color-header);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    overflow: hidden;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .fas {
    aspect-ratio: 1/1;
    align-items: center;
    display: flex;
    font-size: 20px;
    justify-content: center;
  }
}

.leaflet-control-attribution {
  background: none !important;
  color: var(--color-text) !important;
  opacity: 0.5;
  padding: 10px;

  &:hover {
    opacity: 1;
  }

  a {
    color: var(--color-text);
  }
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.filter-bar {
  color: var(--color-header);
  display: flex;
  grid-gap: 10px;
  padding: 20px 0;
  text-transform: uppercase;
  width: 100%;

  li {
    border: 2px solid;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px;

    @media only screen and (max-device-width: 1280px) {
      text-align: center;
      width: 100%;
    }

    &:hover {
      color: var(--color-text);
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.anchored {
      margin-left: auto;
    }

    .fa-solid {
      margin-right: 5px;
    }
  }
}

.clickable-list {
  display: flex;
  grid-gap: 20px;
  flex-direction: column;
  max-height: 560px;
  min-height: 400px;
  overflow-y: scroll;

  .clickable {
    align-items: center;
    color: var(--color-highlight);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    grid-gap: 10px;
    height: 30px;

    &:hover {
      opacity: 0.75;
    }

    .icon {
      color: var(--color-header);
      font-size: 18px;

      &.active {
        color: var(--color-positive);
      }
    }

    .anchored {
      margin-left: auto;
    }

    .meta {
      color: var(--color-header);
    }
  }
}

.pre {
  white-space: pre;
}

.lazyload,
.lazyloading {
  filter: blur(5px);
  opacity: 0;
}
.lazyloaded {
  filter: blur(0);
  opacity: 1;
  transition: filter 200ms, opacity 200ms;
}

.setting-chatbelow-true {
  .has-conversation {
    flex-direction: column;
  }

  app-conversation {
    flex-shrink: 0;
    margin: 0 auto;
    max-width: 800px !important;
    width: 70% !important;

    @media only screen and (max-device-width: 1280px) {
      padding: 0 20px;
      width: 100% !important;
    }
  }
}

.has-conversation {
  display: flex;
  //grid-gap: 20px;

  @media only screen and (max-device-width: 1280px) {
    flex-direction: column;
  }

  app-conversation {
    max-width: 25vw;
    width: 100%;

    @media only screen and (max-device-width: 1280px) {
      max-width: none;
    }
  }
}

.details-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  //display:grid;
  //grid-column-gap: 15px;
  //grid-row-gap: 20px;
  //grid-template-columns: auto 1fr;
  width: 100%;

  @media only screen and (max-device-width: 1280px) {
    display: flex;
    flex-direction: column;
  }

  .wide {
    grid-column: 1 / span 2;
  }

  dt {
    color: var(--color-meta);
    align-items: center;
    display: flex;
    grid-gap: 5px;
    //padding-bottom: 10px;
    text-transform: uppercase;

    .fa-solid, .fa-regular, .fa-brands {
      color: var(--color-header);
      width: 25px;
      //margin-right: 5px;
    }

    a {
      color: #ffffff;

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  dd {
    margin-bottom: 20px;
    overflow: hidden;
    padding-left: 30px;
    text-overflow: ellipsis;

    &:last-child {
      margin-bottom: 0;
    }

    .host {
      cursor: pointer;
      display: block;
      font-size: 15px;
      padding-top: 5px;
      text-transform: uppercase;

      &:hover {
        color: var(--color-highlight);
      }

      img {
        border-radius: 2px;
        display: inline-block;
        height: 18px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }

  .fa-solid, .fa-brands {
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
    //width: 18px;
    //margin-right: 10px;

    &.fa-arrow-up-right-from-square {
      font-size: 14px;
    }
  }
}

.modal.modal-form {
  padding: 0;
  --color-input-border: var(--color-borders);

  .modal-header {
    border-bottom: 2px solid var(--color-borders);
    color: var(--color-highlight);
    display: flex;
    font-size: 24px;
    padding: 20px;

    .action {
      color: var(--color-text);
      cursor: pointer;
      margin-left: auto;

      &:hover {
        color: var(--color-highlight);
      }
    }
  }

  .modal-body {
    display: flex;
    //grid-template-columns: 30% 1fr;
    height: 60vh;
    overflow: hidden;

    @media only screen and (max-device-width: 1280px) {
      flex-direction: column;
      height: auto;
    }

    &.auto {
      height: auto;
    }

    .modal-nav {
      border-right: 2px solid var(--color-borders);
      display: flex;
      flex-direction: column;
      grid-gap: 20px;
      padding: 10px;
      width: 30%;

      @media only screen and (max-device-width: 1280px) {
        width: 100%;
      }
    }

    .modal-panels {
      //height: 100%;
      overflow-y: scroll;
      padding: 0 20px;
      width: 70%;

      @media only screen and (max-device-width: 1280px) {
        width: 100%;
      }

      &.wide {
        width: 100%;
      }

      .modal-panel {
        display: none;
        padding-top: 10px;
        position: relative;

        &.active {
          display: block;
        }

        .form.ng-dirty {
          .action-bar {
            bottom: 20px;
            opacity: 1;
            position: sticky;
          }
        }

        .action-bar {
          align-items: center;
          background: #000000;
          border-radius: 4px;
          bottom: -60px;
          display: flex;
          grid-gap: 20px;
          height: 60px;
          justify-content: flex-end;
          margin-bottom: 20px;
          //opacity: 0;
          padding: 10px;
          position: static;
          transition: all 0.5s ease-in-out;

          &.active {
            bottom: 20px;
            opacity: 1;
            position: sticky;
          }

          &.static {
            position: static !important;
          }

          .action {
            cursor: pointer;
            text-transform: uppercase;
          }

          .button {
            height: 40px;
            line-height: 40px;
            //margin-left: auto;
            padding: 0 20px;
          }
        }
      }
    }
  }
}

.nav-label {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 0 10px;
}

.nav-list {
  display: flex;
  flex-direction: column;
  grid-gap: 0;

  &.scrollable {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &.anchored {
    margin-top: auto;
  }

  li {
    &.inset {
      padding-left: 24px;
    }

    &.active {
      a, .clickable {
        color: var(--color-highlight);

        .icon {
          color: var(--color-highlight);
        }
      }
    }

    &.disabled {
      pointer-events: none;

      a, .clickable, .icon {
        color: var(--color-header);
      }
    }

    a, .clickable {
      align-items: center;
      border-radius: 3px;
      color: var(--color-text);
      cursor: pointer;
      display: flex;
      grid-gap: 10px;
      height: 44px;
      padding: 10px;

      &:hover {
        background: var(--color-borders);
        color: var(--color-highlight);

        .icon {
          color: var(--color-highlight);
        }
      }

      &.active {
        background: var(--color-primary);
        color: #ffffff;

        .icon {
          color: #ffffff;
        }
      }

      &.highlighted {
        //color: var(--color-positive);

        &.active {
          background: var(--color-primary);
          color: #ffffff;
        }

        .icon {
          color: unset;
        }
      }

      &.danger {
        color: var(--color-danger);

        .icon {
          color: unset;
        }
      }

      .icon {
        align-items: center;
        border-radius: 3px;
        color: var(--color-text);
        display: inline-flex;
        font-size: 20px;
        height: 24px;
        justify-content: center;
        text-align: center;
        width: 24px;

        &.anchored {
          margin-left: auto;
        }

        &.meta {
          color: var(--color-header);
        }

        &.thumbnail {
          background: var(--color-borders);

          .initials {
            align-items: center;
            display: flex;
            font-size: 12px;
            height: 24px;
            justify-content: center;
          }
        }
      }
    }
  }
}

.interactable {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.floating-cta {
  align-items: center;
  aspect-ratio: 1/1;
  background: var(--color-header);
  border-radius: 100%;
  bottom: 0;
  box-shadow: 0 0 5px rgba(0,0,0,0.25);
  color: var(--color-text);
  cursor: pointer;
  display: flex;
  font-size: 24px;
  justify-content: center;
  margin: auto ;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  width: 60px;

  &:hover {
    color: var(--color-highlight);
  }
}
